import * as Sentry from '@sentry/react';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

const mockMsw = async () => {
  console.log(`main: initializing mocks in mode: ${process.env.NODE_ENV}`);

  const activeWhen = ['development', 'production'];

  if (activeWhen.includes(process.env.NODE_ENV ?? '')) {
    // eslint-disable-next-line @typescript-eslint/return-await
    return import('./mocks/browser').then(async (module) => {
      return module.worker.start();
    });
    // eslint-disable-next-line @typescript-eslint/return-await
  }
  return Promise.resolve();
};

const mockMinimal = async () => {
  console.log(`main: initializing mocks in mode: ${process.env.NODE_ENV}`);

  const activeWhen = ['development', 'production'];

  if (activeWhen.includes(process.env.NODE_ENV ?? '')) {
    // eslint-disable-next-line @typescript-eslint/return-await
    return import('./mocks/browser-minimal').then(async (module) => {
      return module.worker.start({
        onUnhandledRequest(request, _print) {},
      });
    });
    // eslint-disable-next-line @typescript-eslint/return-await
  }
  return Promise.resolve();
};

const sentry = () => {
  Sentry.init({
    environment: location.hostname,
    enabled: location.hostname !== 'localhost',
    dsn: 'https://33ba9672aafb31c7bded7a1c0c7d4653@o4506300439396352.ingest.us.sentry.io/4506300542484480',
    integrations: [Sentry.browserTracingIntegration()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    tracePropagationTargets: [
      /^https:\/\/flexpavement\.dev/,
      /^https:\/\/coc\.flexpavement\.dev/,
    ],

    // Session Replay
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
};

// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mainMock = () => {
  console.log('main: beginning bootstrap process');
  mockMsw()
    .then(() => {
      console.log('main: mocking promise has completed');
    })
    .then(() => {
      console.log('main: React initialization has begun');
      const container = document.getElementById('root') as HTMLElement;
      const root = createRoot(container);

      root.render(<App />);
      /*
            root.render(
              <React.StrictMode>
                <App />
              </React.StrictMode>,
            );
      */
    })
    .then(() => {
      console.log('main: React initialization is complete');
    })
    .catch((e: any) => {
      console.log(`main: an error has occurred: ${e.message ?? 'unknown'}`);
    })
    .finally(() => {
      console.log('main: bootstrap is complete');
    });
};

const main = () => {
  console.log('main: beginning bootstrap process');
  sentry();
  mockMinimal()
    .then(() => {
      console.log('main: mocking promise has completed');
    })
    .then(() => {
      console.log('main: React initialization has begun');
      const container = document.getElementById('root') as HTMLElement;
      const root = createRoot(container);

      root.render(<App />);
      /*
            root.render(
              <React.StrictMode>
                <App />
              </React.StrictMode>,
            );
      */
    })
    .then(() => {
      console.log('main: React initialization is complete');
    })
    .catch((e: any) => {
      console.log(`main: an error has occurred: ${e.message ?? 'unknown'}`);
    })
    .finally(() => {
      console.log('main: bootstrap is complete');
    });
};

main();
